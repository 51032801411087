#top-nav {
  background-color: var(--offwhite);
}

.top-nav-body {
  max-width: 1400px;
  margin: 0 auto;
}

.top-nav-item {
  position: relative;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: flex-start;
  padding-left: 3em;
  left: -5%;
}

.top-nav-link {
  text-decoration: none;
  color: black;
  font-weight: 300;
  display: block;
}

.top-nav-link:hover {
  color: var(--primary);
  opacity: 0.7;
}

.top-nav-header {
  font-weight: 700;
}

#bottom-nav {
  padding-left: 5em;
  max-width: 1400px;
  margin: 0 auto;
}

#logo_img {
  width: 200px;
}

#bottom-nav .nav-link {
  font-weight: 700;
  color: black;
  padding-right: 2em;
  font-size: 1.2em;
}

#bottom-nav .nav-link:hover {
  color: var(--primary);
  opacity: 0.8;
}

.dropdown_btn a {
  margin-left: 0px;
  margin-right: 0px;
  text-decoration: none;
  font-size: inherit;
}


.dropdown .dropbtn {
  border: none;
  outline: none;
  background-color: inherit;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FAFBFC;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
  width: 210px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.dropdown-content a {
  float: none;
  color: black;
  line-height: 2.5em;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: inherit;
  font-weight: 500;
  left: 1em;
  position: relative;
  width: 80%;
}

.dropdown-content a:hover {
  background-color: #FAFBFC;
  color: var(--primary);
  opacity: 0.7;
}

.dropdown:hover .dropdown-content {
  display: block;
}

#bottom-nav-education {
  font-size: 0.97em;
}

#bottom-nav-mobile-tel {
  position: relative;
  top: -1em;
  left: 1em;
  border-radius: 50%;
}

#bottom-nav-mobile-tel img {
  border-radius: 50%;
}

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
  left: -1em;
}

.navbar-toggler:focus {
  border: 0 !important;
  box-shadow: none !important;
}
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--primary);
  opacity: 1;
  left: 0;
  transition: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  width: 60%;
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* state when navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

@media screen and (max-width: 940px) {

  #top-nav {
    display: none;
  }

  #logo_img {
    width: 120px;
  }

  #bottom-nav {
    padding-left: 2em;
    text-align: right;
  }

  #bottom-nav-mobile-tel img {
    z-index: 999;
    position: relative;
  }

  .sticky {
    position: fixed;
    z-index: 9999999;
    width: 100%;
    top: 0;
    background-color: #fff;

  }

  #side-menu {
    display: none;
  }
}


#side-menu {
  background-color: var(--offwhite);
}

.side-menu-body {
  position: fixed;
  padding: 3em;
}

.side-menu-body img {
  width: 100px;
  height: 100px;
}

.side-menu-links {
  padding-top: 3em;
  left: -1em;
  position: relative;
}

.side-menu-links p {
  padding-bottom: 0.5em;
  width: 80%;
  font-size: 1em;
}

.side-menu-links p a {
  text-decoration: none;
  color: #000;
}

.side-menu-links p:after {
  transform: scaleX(0);
  display:block;
  content: '';
  border-bottom: solid 3px var(--primary);  
  transition: transform 250ms ease-in;
  width: 30%;
}

.side-menu-links p:hover:after{ 
  transform: scaleX(1);
}

.side-menu-links p:hover {
  opacity: 0.7;
}

#top-menu {
  transition: 0.4s;
  display: none;
}

.navbar .navbar-brand {
  padding: 0px;
}

.company_logo {
  width: 180px;
  height: 120px;
  position: relative;
  left: 40px;
  transition: 0.4s;
}

.navbar-nav {
  position: relative;
  left: 20%;
}

.navbar-nav a { 
  font-size: 0.875em;
  font-weight: 800;
  margin-left: 20px;
  margin-right: 20px;
  color:#fff;
  text-align: center;
}

.navbar-nav a:after {
  transform: scaleX(0);
  display:block;
  content: '';
  border-bottom: solid 3px var(--primary);  
  transition: transform 250ms ease-in-out;
  display: none;
}

.navbar-nav a:hover {
  color: #fff;
  border-bottom: solid 3px var(--primary);  
  transition: transform 250ms ease-in-out;
}

.navbar-nav a:hover:after { 
  transform: scaleX(1);
}

#order-online-nav {
  top: -0.4em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  line-height: 3.5em;
  text-align: center;
  padding: 5px;
  position: relative;
  z-index: 1;
  background-color: var(--primary);
  border: 3px solid var(--primary);
  border-radius: 3em;
  color: white;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

#order-online-nav a:hover {
  color: white;
}

#order-online-nav:hover:before {
  width: 100%;
}

#order-online-nav:before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: -1;
  border: 3px solid transparent;
  color: white;
  width: 0;
  -webkit-transition: width .3s;
  transition: width .3s;
}

/* ------------------------ BORDER BOTTOM ON HOVER ------------------------ */

.dropdown {
  float: right;
  position: relative;
  top: 0.3em;
}

#dropdown_btn:hover {
  color: white;
}

#dropdown_btn a {
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 7px;
  text-decoration: none;
  font-size: inherit;
}


.dropdown .dropbtn {
  border: none;
  outline: none;
  color: white;
  font-weight: 800;
  font-size: 0.9em;
  background-color: inherit;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 7px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FAFBFC;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 200px;
  padding-top: 0.6em;
}

.dropdown-content a {
  float: none;
  color: black;
  line-height: 3.5em;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.dropdown-content a:after {
  transform: scaleX(0);
  display:block;
  content: '';
  border-bottom: none;
  border: 2px solid var(--primary);
  border-radius: 4em;
  height: 3em;
  top: -4em;
  margin-right: 0.5em;
  transition: transform 250ms ease-in-out;
  display: flex;
  float: left;
  display: none;
}

.dropdown-content a:hover {
  background-color: #FAFBFC;
  color: #000;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.mobile-item {
  display: none;
}

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler:focus {
  border: 0 !important;
  box-shadow: none !important;
}
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: black;
  opacity: 1;
  left: 0;
  transition: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  width: 60%;
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* state when navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

.mobile-nav-services {
  display: none;
}

/* --------------------------------------- NAVBAR MOBILE --------------------------------- */

@media screen and (max-width:940px) {

  #top-menu {
    display: block;
  }

  .navbar .navbar-brand {
    font-size: 40px !important;
  }  
  #top-menu {
    background-color: #F4F9FF;
    box-shadow: 80px 0px rgba(0,0,0,0.6);
  }

  .dropdown {
    display: none;
  }

  .mobile-nav-services .dropdown {
    display: block;
  }

  .navbar-nav {
    left: 0%;
  }

  .navbar-nav a { 
    font-size: 1em;
    text-align: left;
    flex-direction: column;
    color: black;
    padding-top: 16px;
    padding-bottom: 20px;
    padding-right: 20px;
    letter-spacing: 0.6vw;
    margin-bottom: 10px;
    position: relative;
    border-bottom: none;
  }

  .navbar-nav a:after {
    display: none;
  }

  .navbar-nav a:hover {
    color: var(--primary);
  }
  
  .navbar-brand .company_logo {
    width: 120px;
    height: 80px;
    position: relative;
    left: 0px;
  }  

  #order-online-nav {
    background-color: transparent;
    border: none;
    font-size: 1.1em;
  }

  #order-online-nav:hover {
    background-color: transparent;
  }

  #order-online-nav:before {
    display: none;
  }

  .mobile-nav-services {
    display: block;
  }

}