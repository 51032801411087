/* ---------------------------  HISTORY   --------------------------- */

#history-page .home-about-section { 
    padding-top: 0em;  
    padding-bottom: 10em; 
}

#history-page .home-about-content {
    padding-top: 0em;
}

#history-page .home-about-content-text {
    padding-top: 0em;
}

#history-page .home-about-section .about-img:before {
    content: '1,000+ citas creadas';
    font-weight: 700;
    color: #fff;
    position: absolute;
    display: block;
    height: 200px;
    width: 200px;
    text-align: center;
    font-size: 1.6em;
    border-radius: 50%;
    padding: 1.5em;
    padding-top: 1em;
    background-color: var(--primary);
    border: 0.4em solid #fff;
    top: 16em;
    left: 6.2em;
    z-index: 1;
}

.history-now {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    background-color: var(--primary);
    left: -2em;
    margin-top: 8em;
    padding: 4em;
}

.history-now h1 {
    font-family: 'Suez One';
    width: 100%;
}

.history-now p {
    font-weight: 300;
    width: 100%;
}

/* ---------------------------  HOW VACCINES WORK   --------------------------- */


.white-card {
    background-color: var(--primary);
    border-radius: 0px;
    border: none;
    color: #fff;
    transition: 0.1s;
}

.white-card-body {
    padding-bottom: 3em;
}

.white-card .card-title {
    font-weight: 700;
    font-size: 2em;
}

.white-card-image {
    transition: 0.1s;
    position: relative;
}

.visit-us-section .card-content .main-button {
    color: var(--primary);
    padding: 0em 1.4em;
    background-color: #fff;;
}

.visit-us-section .card-content .main-button:hover {
    color: white;
}

.visit-us-section .card-content .card-text {
    padding: 2em;
    font-size: 1.2em;
}

.card-second-child {
    padding-bottom: 1em;
}

.mobile-card {
    display: none;
}



/* ---------------------------  COMMON QUESTIONS   --------------------------- */



#common-questions-page .security-text {
    padding-top: 2em;
    padding: 2em;
    padding-left: 5em;
    margin-bottom: 2em;
    border: 5px solid var(--primary);
    height: 250px;
}

#common-questions-page .question-marks {
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-size: 1.6em;
    border-radius: 50%;
    padding: 1.5em;
    width: 150px;
    height: 150px;
    padding-top: 1em;
    left: 3.5em;
    top: 2em;
    background-color: var(--primary);
    border: 0.4em solid #fff;
    z-index: 1;
}

#common-questions-page .security-text h1 {
    font-size: 1.5em;
    font-family: "Suez One";
}


#common-questions-page .security-text p {
    padding-top: 1em;
    font-size: 1.2em;
}  

.questions {
    padding-bottom: 5em;
    max-width: 1200px;
}


/* ---------------------------  RECOMMENDATIONS   --------------------------- */

#recommend-page .security {
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    background-color: var(--primary);
    margin-bottom: 2em;
    padding: 4em;
    padding-bottom: 3.5em;
}

#recommend-page h1 {
    font-family: 'Suez One';
}

#recommend-page .recommendations {
    max-width: 1250px;
    padding-bottom: 5em;
}

#recommend-page .recommendations img {
    width: 100%;
    object-fit: cover;
}

#recommend-page .black-white-img {
    height: 600px;
    padding-bottom: 3em;
}

#recommend-page .first {
    height: 800px;
}

#recommend-page .third {
    height: 700px;
}

#recommend-page .security-text {
    padding-top: 2em;
    padding: 2em;
    margin-bottom: 2em;
    border: 5px solid var(--primary);
}

#recommend-page .security-text h1 {
    font-size: 1.5em;
}


#recommend-page .security-text p {
    padding-top: 1em;
    font-size: 1em;
}  

.credits {
    text-align: center;
    font-size: 0.9em;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 6em;
}

/* ---------------------------  VACCINE SAFETY   --------------------------- */

#vaccine-safety-page .security {
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    background-color: var(--primary);
    margin-bottom: 2em;
    padding: 4em;
    padding-bottom: 3.5em;
}

#vaccine-safety-page h1 {
    font-family: 'Suez One';
}

#vaccine-safety-page .vaccine-safety {
    max-width: 1250px;
    padding-bottom: 5em;
}

#vaccine-safety-page .vaccine-safety img {
    width: 100%;
}

#vaccine-safety-page .black-white-img {
    height: 600px;
}

#vaccine-safety-page .security-text {
    padding-top: 2em;
}

#vaccine-safety-page .security-text p {
    padding-top: 1em;
}  



/* ---------------------------  VACCINE SERVICE   --------------------------- */

#free {
    border: 5px solid #fff;
    background-color: var(--primary);
    color: #fff;
    width: 220px;
    height: 220px;
    margin: 0 auto;
    padding: 2em;
    padding-top: 3em;
    border-radius: 50%;
    position: relative;
    top: -10em;
}

#free h1 {
    font-size: 2.6em;
    text-align: center;
    margin: 0 auto;
}

#service-section .header {
    text-align: center;
    margin-top: -4em;
    width: 60%;
    padding-bottom: 4em;
}

#service-section .security {
    width: 80%;
    font-size: 1.2em;
    padding: 2em;
}

#service-form {
    width: 80%;
    padding-bottom: 3em;
    margin: 0 auto;
}

#service-form .form-control {
    border: none;
}

#service-form .form-group .form-control {
    border-bottom: 3px solid var(--primary);
    border-radius: 0px;
    margin-bottom: 0.2em;
}

#service-form .form-label {
    font-weight: 700;
    font-size: 1.2em;
    padding-top: 1em;
}

#service-form .security {
    margin-top: 3em;
    width: 100%;
    margin-bottom: 3em;
}

.jotform {
    position: relative;
    top: 10em;
    margin-top: -10em;
    margin-bottom: -10em;
}

@media screen and (max-width: 940px) {

    #visit-us-page .google-map {
        width: 100%;
        height: 300px;
        margin-bottom: 2em;
    }
    
    .white-card {
        display: none;
    }

    .mobile-card img {
        width: 100%;
    }

    .mobile-card {
        display: block;
        width: 300px;
        margin: 0 auto;
        background-color: var(--primary);
        color: #fff;
    }

    .mobile-card .card-title {
        font-size: 2em;
    }
    
    .mobile-card .main-button {
        background-color: #fff;
    }

    .mobile-content {
        display: block;
        padding: 1em;
    }

    .mobile-content img,
    .mobile-content iframe {
        width: 100%;
        padding-bottom: 1em;
    }
    
    .mobile-full-text {
        display: none;
    }
    
    .mobile-close {
        display: none;
    }

    #history-page .home-about-section .about-img:before {
        content: '1,000+ citas creadas';
        font-weight: 700;
        color: #fff;
        position: absolute;
        display: block;
        height: 200px;
        width: 200px;
        text-align: center;
        font-size: 1.6em;
        border-radius: 50%;
        padding: 1.5em;
        padding-top: 1em;
        background-color: var(--primary);
        border: 0.4em solid #fff;
        top: 18em;
        left: 4.5em;
        z-index: 1;
        opacity: 1;
    }

    .history-now {
        left: 0em;
        width: 80%;
    }

    #common-questions-page .security-text {
        height: 100%;
        padding-left: 2em;
        padding-top: 5em;
    }

    #common-questions-page .question-marks {
        left: 4em;
        top: 2.5em;
    }

    #service-section .header {
        width: 90%;
    }

    #vaccine-safety-page .security {
        width: 80%;
        padding: 2em;
    }

    #service-form .security {
        width: 100%;
    }

    .jotform:before {
        content: '';
        position: absolute;
        display: block;
        height: 80px;
        width: 100%;
        background-color: #fff;
        top: 222em;
        left: 2em;
        z-index: 999;
        display: none;
    }

    #vaccine-safety-page .black-white-img {
        height: 500px;
        padding-bottom: 2em;
    }

    #recommend-page .recommendations img {
        height: 500px;
    }

}