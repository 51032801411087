:root {
  --primary: #25528F;
  --offwhite: #F4F9FF;
  --lightblue: #B7D2F5;
  --lightshadow: 0px 8px 8px 0px rgba(0,0,0,0.1);
  --cardshadow: 0 8px 30px 0 rgba(25,25,25,.1);
}

/* Mobile */

@media only screen and (min-width: 0em) {
  body,
  html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  body {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .container {
    position: relative;
    width: 92%;
    margin: auto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p,
  li,
  a {
    line-height: 1.5em;
  }

  p {
    font-size: 1.2em;
    line-height: 1.5em;
    letter-spacing: 0.05em;

  }
  
  a:hover,
  button:hover {
    cursor: pointer;
  }
  .main-button-white {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    width: auto;
    line-height: 3.5em;
    text-align: center;
    padding: 0 2em;
    position: relative;
    z-index: 1;
    background-color: white;
    border: 3px solid white;
    color: var(--primary);
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-transition-delay: .1s;
            transition-delay: .1s;
  }
  .main-button-white:hover {
    color: white;
    border:  3px solid #212121;
    text-decoration: none;
  }
  .main-button-white:hover:before {
    width: 100%;
  }
  .main-button-white:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: black;
    width: 0;
    -webkit-transition: width .3s;
    transition: width .3s;
  }

  .main-button {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    width: auto;
    line-height: 3em;
    color: white;
    text-align: center;
    padding: 0.5em 2em;
    position: relative;
    z-index: 1;
    background-color: var(--primary);
    border: 3px solid var(--primary);
    border-radius: 50px;
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-transition-delay: .1s;
            transition-delay: .1s;
  }
  
  .main-button:hover {
    color: black;
    border:  3px solid var(--lightblue);
    text-decoration: none;
  }
  .main-button:hover:before {
    width: 100%;
  }
  .main-button:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--lightblue);
    border-radius: 50px;
    width: 0;
    -webkit-transition: width .3s;
    transition: width .3s;
  }

  .section-header {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0.3rem;
  }

  .page-header {
    position: relative;
  }
  
  .page-header-img {
    position: relative;
    top: -60px;
    width: 100%;
    height: 400px;
    object-fit: cover;
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
  }
  
  .page-header-title {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    color: white;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    z-index: 99;
    margin: 0 auto;
  }

  .page-header-title h1 {
    font-size: 4em;
    font-family: "Suez One";  
  }

  .page-header-title h4 {
    font-weight: 200;
  }

}
/* Reset Margins */
@media only screen and (min-width: 1024px) {
  body,
  html {
    padding: 0;
    margin: 0;
  }
}
/* Scale full website with the viewport width */
@media only screen and (min-width: 2000px) {
  body,
  html {
  }
}
/* Desktop */
@media only screen and (min-width: 64em) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.375em rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #f0f0f0;
  }
  ::-webkit-scrollbar {
    width: 0em;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.625em;
    background-color: #F5F5F5;
  }
}

@import url('https://fonts.googleapis.com/css?family=Suez+One');

@media screen and (max-width: 940px) {

  .page-header {
    position: relative;
  }
  
  .page-header-img {
    position: relative;
    top: -60px;
    width: 100%;
    height: 400px;
    object-fit: cover;
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
  }
  
  .page-header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    color: white;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    z-index: 99;
    margin: 0 auto;
  }

  .page-header-title h1 {
    font-size: 2em;
  }

  .page-header-title h4 {
    font-weight: 200;
    width: 100%;
  }

}