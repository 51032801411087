#footer-section {
    background-color: var(--primary);  
    position: relative;
}

.footer-body {
    max-width: 1500px;
    margin: 0 auto;
}
  
.footer-title {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    width: 200px;
    text-align: left;
}
  
.footer-img {
    margin-top: 40px;
    width: 200px;
}
  
.footer-phone, .footer-email, .footer-local {
    color: white;
    letter-spacing: 0.3vh;
}

.footer-phone:hover, .footer-email:hover, .footer-local:hover  { 
    color: var(--primary);
}
  
.footer-txt {
    padding-top: 5em;
    color: white;
    position: relative;
    letter-spacing: 0.3vh;
    line-height: 2em;
}
  
.copyright-txt {
    color: white;
    padding: 10px;
    text-align: right;
}

.footer {
    padding-bottom: 4em;
}

.footer h4 {
    font-weight: 800;
    padding-bottom: 1em;
    text-transform: uppercase;
}

.footer .header {
    font-weight: 700;
}

.footer a {
    color: white;
}

.footer a:hover {
    color: var(--lightblue)
}
  
@media screen and (max-width: 940px) {
    .footer-col {
        width: 100%;
    }
    footer * {
        border-radius:0;
    }

    .footer-txt {
        left: 0%;
    }

    .copyright-footer {
        border-top: none;
        color: white;
        text-align: center;
        font-size: 0.7em;
    }

    .footer-top {
        padding-left: 1.9em;
        padding-right: 1.9em;
        text-align: center;
    }

    .footer-top h4 {
        padding-bottom: 1.2em;
    }

    .footer-top .links-col {
        padding: 0px;
        display: flex;
        left: -1em;
        padding-top: 1.5em;
    }

    .footer-top ul {
        float: none;
        width: 120px;
    }

    #right-side {
        padding-left: 4em;
        padding-right: 4em;
        text-align: center;
        font-size: 0.8em;
    }

    .copyright-txt {
        text-align: center;
        font-size: 0.95em;
    }

}